import type {HandleClientError} from '@sveltejs/kit'
import * as Sentry from '@sentry/sveltekit'

import {dev} from '$app/environment'
import {PUBLIC_SENTRY_DSN} from '$env/static/public'

if (!dev && PUBLIC_SENTRY_DSN) {
  console.log('* noah sentry init - client')
  Sentry.init({
    environment: dev ? 'development' : 'production',
    dsn: PUBLIC_SENTRY_DSN,
    // 관리툴은 사용자가 많지 않아 100% tracing 한다.
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [Sentry.replayIntegration()],
  })

  Sentry.setTag('app.name', 'noah')
  Sentry.setTag('app.layer', 'client')
}

const errorHandler = ((input) => {
  console.error(input.error)
}) satisfies HandleClientError

export const handleError = Sentry.handleErrorWithSentry(errorHandler)
